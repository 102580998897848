import { Environment } from '../models/environment';

export const environment: Environment = {
  environment: 'demo',
  production: true,
  api: {
    movinmotionBackend: 'https://back.app.demo.movinmotion.net',
    movinmotionService: 'https://services.demo.movinmotion.net',
  },
  appEndpoints: {
    talents: '/api/talent/v1',
    core: '/api/core/v1',
    filmfrance: 'api/filmfrance/v1',
    nda: '/api/nda/v1',
    movinmotion: '/api/movinmotion/v1',
  },
  authentication: {
    url: 'https://accounts.app.demo.movinmotion.net',
    queryParamKeys: {
      token: 'mmt',
      entropy: 'mment',
      redirectUrl: 'mmr',
      resetPassword: 'mmrp',
      changePassword: 'mmcp',
      email: 'mme',
      movinmotionAuthError: 'mmae',
      language: 'mml',
    },
  },
  apps: {
    core: 'https://entreprise.app.demo.movinmotion.net',
    worker: 'https://salarie.app.demo.movinmotion.net',
    compta: 'https://compta.app.demo.movinmotion.net',
    talents: 'https://talents.app.demo.movinmotion.net',
    insurance: 'https://assurance.app.demo.movinmotion.net',
    socialPlay: 'https://app.demo.movinmotion.net',
    social: 'https://social.app.demo.movinmotion.net',
    admin: 'https://admin.app.demo.movinmotion.net',
    movinmotion: 'https://www.movinmotion.com',
  },
  thirdParty: {
    siren: {
      endpoint: 'https://api.insee.fr/entreprises/sirene/V3.11',
      token: 'f6306046-6677-38bf-bc0a-93122dfbd29d',
    },
    googlePlaces: 'AIzaSyAB5jK2vCWZSpWJLBofr46_GOV3l2RS-fY',
  },
  bucket: {
    avatars: 'avatars.storage.demo.movinmotion.net',
  },
  cookieDomain: '.app.demo.movinmotion.net',
};
